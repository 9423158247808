<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车场管理</el-breadcrumb-item>
      <el-breadcrumb-item>停车场列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >是否在线：
          <el-select
            v-model="query.isOnline"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="在线" :value="1"> </el-option>
            <el-option label="不在线" :value="0"> </el-option>
          </el-select>
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="pkInfoListcx"
          >查询</el-button
        >
        <el-button size="small" @click="pkInfoListcz">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAddDialog"
        >添加</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          element-loading-text="正在加载中，大约一分钟左右···"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="pkName"
            label="停车场名称"
            width="100"
          >
            <template slot-scope="scope">
              <span style="color: #409eff">{{ scope.row.pkName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="isOnline"
            label="状态"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.isOnline === 1">在线</span>
              <span v-show="scope.row.isOnline === 0">不在线</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="totalBerthNum"
            label="泊位总数"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="pkCode"
            label="车场编号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="添加时间"
            sortable
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="h5PrepayQrcode"
            label="提前付H5二维码"
            width="130"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.h5PrepayQrcode"
                :preview-src-list="h5imgList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="wxMiniProQrcode"
            label="提前付微信二维码"
            width="140"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.wxMiniProQrcode"
                :preview-src-list="wximgList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="alipayMiniProQrcode"
            label="提前付支付宝二维码"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="scope.row.alipayMiniProQrcode"
                :preview-src-list="alipaybimgList"
              >
              </el-image>
            </template>
          </el-table-column>

          <!-- prop="alipayMiniProQrcode" -->
          <el-table-column
            header-align="left"
            align="left"
            label="H5发票二维码"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 40px; height: 40px"
                :src="hqh5QrcodeUrl(scope)"
                :preview-src-list="[hqh5QrcodeUrl(scope)]"
              >
                <template #fallback>
                  <div class="image-fallback">图片加载失败</div>
                </template>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            :width="isShowGLY ? 440 : 350"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                v-if="isShowGLY"
                type="text"
                size="mini"
                icon="el-icon-arrow-right"
                @click="isShowCmdDialog(scope.row)"
                >安装指令</el-button
              >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-share"
                @click="isShowGtDialog(scope)"
                >岗亭端</el-button
              >
              <el-button
                type="text"
                @click="isShowSetDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >发票设置</el-button
              >
              <el-button
                type="text"
                @click="baiWangInvoiceDialog(scope.row)"
                size="mini"
                icon="el-icon-share"
                >登录百旺全电开票</el-button
              >
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="isShowmodeDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >支付方式</el-button
              >
              <el-button
                type="text"
                @click="isShowxjzlDialog(scope)"
                size="mini"
                icon="el-icon-share"
                >现金找零</el-button
              >
              <el-button
                type="text"
                @click="couponRecoveryClick(scope.row)"
                size="mini"
                icon="el-icon-open"
                >优惠券回收</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加停车场"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="60%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="停车场名称" prop="pkName">
          <el-input
            v-model="addForm.pkName"
            placeholder="请输入停车场名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkman">
          <el-input
            v-model="addForm.linkman"
            placeholder="请输入联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="在线状态" prop="isOnline">
          <el-select
            style="width: 150px"
            v-model="addForm.isOnline"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in onlineStatuoptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="addForm.tel"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="总车余位" prop="totalBerthNum">
          <el-input
            v-model="addForm.totalBerthNum"
            placeholder="请输入总车余位"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="收费标准" prop="feeScale">
          <el-input
            v-model="addForm.feeScale"
            placeholder="请输入收费标准"
          ></el-input>
        </el-form-item>
        <el-form-item label="开放时间" prop="openTime">
          <el-input
            v-model="addForm.openTime"
            placeholder="请输入开放时间"
          ></el-input>
        </el-form-item>
        <el-form-item label="泊位总数" prop="totalBerthNum">
          <el-input
            v-model="addForm.totalBerthNum"
            placeholder="请输入泊位总数"
          ></el-input>
        </el-form-item>
        <el-form-item label="开放泊位数" prop="openBerthNum">
          <el-input
            v-model="addForm.openBerthNum"
            placeholder="请输入开放泊位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="临停车位数" prop="tempTotalNum">
          <el-input
            v-model="addForm.tempTotalNum"
            placeholder="请输入临停车位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="月租车位数" prop="intrinsicTotalNum">
          <el-input
            v-model="addForm.intrinsicTotalNum"
            placeholder="请输入月租车位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="访客车位数" prop="visitorTotalNum">
          <el-input
            v-model="addForm.visitorTotalNum"
            placeholder="请输入访客车位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="充电桩车位数" prop="chargeTotalNum">
          <el-input
            v-model="addForm.chargeTotalNum"
            placeholder="请输入充电桩车位数"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formitems"
          label="高德经纬度坐标"
          label-width="120px"
        >
          <span>经度：</span>
          <el-input
            v-model="addForm.gmapX"
            placeholder="请输入经度"
            style="width: 150px"
          ></el-input>
          <span style="margin-left: 12px">纬度：</span>
          <el-input
            v-model="addForm.gmapY"
            placeholder="请输入纬度"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formitems"
          label="百度经纬度坐标"
          label-width="120px"
        >
          <span>经度：</span>
          <el-input
            v-model="addForm.bmapX"
            placeholder="请输入经度"
            style="width: 150px"
          ></el-input>
          <span style="margin-left: 12px">纬度：</span>
          <el-input
            v-model="addForm.bmapY"
            placeholder="请输入纬度"
            style="width: 150px"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item class="formitems" label="经纬度">
          <span>经度：{{ addForm.gmapX }} </span>
          <span>纬度：{{ addForm.gmapY }} </span>
          <div id="myPageTop">
            <input id="tipinput" placeholder="请输入具体位置" />
          </div>
          <div id="container"></div>
        </el-form-item> -->
        <el-form-item label="所属地区">
          <el-select
            style="width: 150px"
            v-model="addForm.provinceCode"
            @focus="sheng"
            placeholder="请选择省"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px; margin: 0 20px"
            v-model="addForm.cityCode"
            @focus="shi"
            placeholder="请选择市"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="addForm.regionCode"
            @focus="qu"
            placeholder="请选择区"
          >
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="addForm.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="盒子远程访问网址:"
          prop="subDomain"
          label-width="150px"
        >
          <el-input
            style="width: 300px"
            v-model="addForm.subDomain"
            placeholder="请输入网址"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑停车场"
      :visible.sync="isShowEdit"
      width="60%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="停车场名称" prop="pkName">
          <el-input
            v-model="editForm.pkName"
            placeholder="请输入停车场名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkman">
          <el-input
            v-model="editForm.linkman"
            placeholder="请输入联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="在线状态" prop="isOnline">
          <el-select
            style="width: 150px"
            v-model="editForm.isOnline"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in onlineStatuoptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="editForm.tel"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="总车余位" prop="totalBerthNum">
          <el-input
            v-model="editForm.totalBerthNum"
            placeholder="请输入总车余位"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="收费标准" prop="feeScale">
          <el-input
            v-model="editForm.feeScale"
            placeholder="请输入收费标准"
          ></el-input>
        </el-form-item>
        <el-form-item label="开放时间" prop="openTime">
          <el-input
            v-model="editForm.openTime"
            placeholder="请输入开放时间"
          ></el-input>
        </el-form-item>
        <el-form-item label="泊位总数" prop="totalBerthNum">
          <el-input
            v-model="editForm.totalBerthNum"
            placeholder="请输入泊位总数"
          ></el-input>
        </el-form-item>
        <el-form-item label="开放泊位数" prop="openBerthNum">
          <el-input
            v-model="editForm.openBerthNum"
            placeholder="请输入开放泊位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="临停车位数" prop="tempTotalNum">
          <el-input
            v-model="editForm.tempTotalNum"
            placeholder="请输入临停车位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="月租车位数" prop="intrinsicTotalNum">
          <el-input
            v-model="editForm.intrinsicTotalNum"
            placeholder="请输入月租车位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="访客车位数" prop="visitorTotalNum">
          <el-input
            v-model="editForm.visitorTotalNum"
            placeholder="请输入访客车位数"
          ></el-input>
        </el-form-item>
        <el-form-item label="充电桩车位数" prop="chargeTotalNum">
          <el-input
            v-model="editForm.chargeTotalNum"
            placeholder="请输入充电桩车位数"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formitems"
          label="高德经纬度坐标"
          label-width="120px"
        >
          <span>经度：</span>
          <el-input
            v-model="editForm.gmapX"
            placeholder="请输入经度"
            style="width: 150px"
          ></el-input>
          <span style="margin-left: 12px">纬度：</span>
          <el-input
            v-model="editForm.gmapY"
            placeholder="请输入纬度"
            style="width: 150px"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="formitems"
          label="百度经纬度坐标"
          label-width="120px"
        >
          <span>经度：</span>
          <el-input
            v-model="editForm.bmapX"
            placeholder="请输入经度"
            style="width: 150px"
          ></el-input>
          <span style="margin-left: 12px">纬度：</span>
          <el-input
            v-model="editForm.bmapY"
            placeholder="请输入纬度"
            style="width: 150px"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item class="formitems" label="经纬度">
          <span>经度：{{ editForm.gmapX }} </span>
          <span>纬度：{{ editForm.gmapY }} </span>
          <div id="myPageTop">
            <input id="tipinput" placeholder="请输入具体位置" />
          </div>
          <div id="containers"></div>
        </el-form-item> -->
        <el-form-item label="所属地区">
          <el-select
            style="width: 150px"
            v-model="editForm.provinceCode"
            @focus="sheng"
            placeholder="请选择省"
          >
            <el-option
              v-for="item in options"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px; margin: 0 20px"
            v-model="editForm.cityCode"
            @focus="shi"
            placeholder="请选择市"
          >
            <el-option
              v-for="item in options2"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="editForm.regionCode"
            @focus="qu"
            placeholder="请选择区"
          >
            <el-option
              v-for="item in options3"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="editForm.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="停车场英文名称:"
          prop="subDomain"
          label-width="150px"
        >
          <el-input
            style="width: 300px"
            v-model="editForm.subDomain"
            placeholder="请输入停车场英文名称"
          ></el-input>
          （用于远程访问）
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 发票设置 -->
    <el-dialog
      title="添加发票设置"
      :visible.sync="isShowSet"
      @close="setClose"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        :model="setForm"
        :rules="formRules"
        ref="setRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="开票提供方" prop="invoiceProvider">
          <el-radio-group v-model="setForm.invoiceProvider">
            <el-radio :label="1">诺诺网</el-radio>
            <el-radio :label="2">百旺</el-radio>
            <el-radio :label="3">百旺全电</el-radio>
            <el-radio :label="4">诺诺网全电</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 1"
          label="身份认证"
          label-width="160px"
          prop="providerConfigParam"
        >
          <el-input
            v-model="setForm.providerConfigParam"
            placeholder="请输入身份认证"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 4"
          label="appKey"
          label-width="160px"
          prop="appKey"
        >
          <el-input v-model="setForm.appKey" placeholder="请输入Key"></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 4"
          label="appSecret"
          label-width="160px"
          prop="appSecret"
        >
          <el-input
            v-model="setForm.appSecret"
            placeholder="请输入Secret"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 4"
          label="token"
          label-width="160px"
          prop="token"
        >
          <el-input
            v-model="setForm.token"
            placeholder="请输入token"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 4"
          label="备注"
          label-width="160px"
          prop="remark"
        >
          <el-input
            v-model="setForm.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 2"
          label="开票终端标识"
          label-width="160px"
          prop="kpzdbs"
        >
          <el-input
            v-model="setForm.kpzdbs"
            placeholder="请输入开票终端标识"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 2"
          label="机器编号"
          label-width="160px"
          prop="jqbh"
        >
          <el-input
            v-model="setForm.jqbh"
            placeholder="请输入机器编号"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 2 || setForm.invoiceProvider == 3"
          :label="
            setForm.invoiceProvider == 2 ? '企业代码' : '企业代码(百旺提供)'
          "
          label-width="160px"
          prop="enterpriseCode"
        >
          <el-input
            v-model="setForm.enterpriseCode"
            placeholder="请输入企业代码"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="setForm.invoiceProvider == 3"
          label="企业账号"
          label-width="160px"
          prop="dlzh"
        >
          <el-input
            v-model="setForm.dlzh"
            placeholder="请输入企业账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="销售方名称" label-width="160px" prop="saleName">
          <el-input
            v-model="setForm.saleName"
            placeholder="请输入销售方名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="销售方税号" label-width="160px" prop="saleTaxnum">
          <el-input
            v-model="setForm.saleTaxnum"
            placeholder="请输入销售方税号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="销方银行账号"
          label-width="160px"
          prop="saleAccount"
        >
          <el-input
            v-model="setForm.saleAccount"
            placeholder="请输入销方银行账号(例如:xx银行xx分行营业部 1234567890)"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="销方开户行"
          label-width="160px"
          prop="saleOpenBank"
        >
          <el-input
            v-model="setForm.saleOpenBank"
            placeholder="请输入销方开户行"
          ></el-input>
        </el-form-item>
        <el-form-item label="销方电话" label-width="160px" prop="salePhone">
          <el-input
            v-model="setForm.salePhone"
            placeholder="请输入销方电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="销方地址" label-width="160px" prop="saleAddress">
          <el-input
            v-model="setForm.saleAddress"
            placeholder="请输入销方地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票员" label-width="160px" prop="clerk">
          <el-input
            v-model="setForm.clerk"
            placeholder="请输入开票员"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款人" label-width="160px" prop="payee">
          <el-input
            v-model="setForm.payee"
            placeholder="请输入收款人"
          ></el-input>
        </el-form-item>
        <el-form-item label="复核人" label-width="160px" prop="checker">
          <el-input
            v-model="setForm.checker"
            placeholder="请输入复核人"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票税率" label-width="160px" prop="taxrate">
          <el-input
            v-model="setForm.taxrate"
            placeholder="请输入开票税率0.00"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票货物名称" label-width="160px" prop="goodsName">
          <el-input
            v-model="setForm.goodsName"
            placeholder="开票货物名称（默认为“车辆停放服务”）"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票货物编码" label-width="160px" prop="goodsCode">
          <el-input
            v-model="setForm.goodsCode"
            placeholder="请输入开票货物编码（默认为“3040502020200000000”）"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票提示语" label-width="160px" prop="goodsCode">
          <el-input
            type="textarea"
            :rows="2"
            v-model="setForm.showMsg"
            placeholder="开票错误提示语，用于开票端展示给车主看"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="产权证书号码"
          label-width="160px"
          prop="fwcqzshm"
        >
          <el-input
            v-model="setForm.fwcqzshm"
            placeholder="房屋产权证书/不动产权证号码"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="不动产地址"
          label-width="160px"
          prop="bdcdz"
        >
          <el-input v-model="setForm.bdcdz" placeholder="不动产地址"></el-input>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="不动产详细地址"
          label-width="160px"
          prop="bdcxxdz"
        >
          <el-input
            type="textarea"
            :rows="2"
            v-model="setForm.bdcxxdz"
            placeholder="不动产详细地址"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="租赁期起"
          label-width="160px"
          prop="zlqq"
        >
          <el-date-picker
            v-model="setForm.zlqq"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择租赁期起"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="租赁期止"
          label-width="160px"
          prop="zlqz"
        >
          <el-date-picker
            v-model="setForm.zlqz"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择租赁期止"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="跨地市标志"
          label-width="160px"
          prop="kdsbz"
        >
          <el-radio-group v-model="setForm.kdsbz">
            <el-radio :label="'Y'">是</el-radio>
            <el-radio :label="'N'">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-if="setForm.invoiceProvider == 3 || setForm.invoiceProvider == 4"
          label="面积单位"
          label-width="160px"
          prop="mjdw"
        >
          <el-select v-model="setForm.mjdw" placeholder="请选择面积单位">
            <el-option
              v-for="item in areaUnitList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowSet = false">取 消</el-button>
        <el-button type="primary" @click="setClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 安装指令 -->
    <el-dialog
      title="安装指令"
      :visible.sync="isShowAZZl"
      @close="installCmd = null"
      width="50%"
      v-el-drag-dialog
    >
      <el-form label-width="100px" class="demo-ruleForm">
        <el-form-item label="指令">
          <el-input v-model="installCmd" type="textarea" autosize readonly>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-clipboard:copy="installCmd"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          >复制</el-button
        >
        <el-button type="primary" @click="isShowAZZl = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 现金找零 -->
    <el-dialog
      title="现金找零"
      :visible.sync="isShowxjzl"
      width="60%"
      v-el-drag-dialog
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="支付宝找零" name="first">
          <el-form
            :model="xjzlForm"
            :rules="formRules"
            ref="editRef"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item label="当前停车场">
              <span>{{ xjzlForm.pkName }}</span>
            </el-form-item>
            <el-form-item label="APPIP" prop="appId">
              <el-input
                v-model="xjzlForm.appId"
                placeholder="请输入APPIP"
              ></el-input>
            </el-form-item>
            <el-form-item label="开发者应用私钥" prop="appPrivateKey">
              <el-input
                v-model="xjzlForm.appPrivateKey"
                type="textarea"
                :rows="5"
                placeholder="请输入开发者应用私钥"
              ></el-input>
            </el-form-item>
            <el-form-item label="应用公钥证书">
              <el-upload
                class="upload-demo"
                action=""
                :auto-upload="false"
                :before-remove="beforeRemove1"
                :on-change="onchange1"
                multiple
                :http-request="uploadHttp1"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList1"
              >
                <el-button size="small" type="primary">{{
                  uploadzs1
                }}</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="支付宝公钥证书">
              <el-upload
                class="upload-demo"
                action=""
                :auto-upload="false"
                :before-remove="beforeRemove2"
                :on-change="onchange2"
                multiple
                :http-request="uploadHttp2"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList2"
              >
                <el-button size="small" type="primary">{{
                  uploadzs2
                }}</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="支付宝CA根证书">
              <el-upload
                class="upload-demo"
                action=""
                :auto-upload="false"
                :before-remove="beforeRemove3"
                :on-change="onchange3"
                multiple
                :http-request="uploadHttp3"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList3"
              >
                <el-button size="small" type="primary">{{
                  uploadzs3
                }}</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="微信找零" name="second"
          >微信找零暂不支持</el-tab-pane
        >
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowxjzl = false">取 消</el-button>
        <el-button type="primary" @click="xjzlClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 优惠劵回收 -->
    <el-dialog
      title="优惠劵过期回收"
      :visible.sync="isShowCouponRecovery"
      width="50%"
      @close="couponRecoveryClose"
      v-el-drag-dialog
    >
      <el-switch
        v-model="isopencouponreturn"
        active-color="#409eff"
        inactive-color="#ff4949"
        inactive-text="是否开启优惠券过期回收"
        @change="couponRecoveryChange()"
      >
      </el-switch>
    </el-dialog>
    <!-- 百旺通全电开票 -->
    <el-dialog
      title="百旺通全电开票"
      :visible.sync="isShowBaiWangInvoiceDialog"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        label-width="150px"
        label-position="left"
        :model="baiWangLogonForm"
        :rules="invoiceRules"
        ref="invoiceForm"
      >
        <el-form-item label="纳税人识别号" prop="nsrsbh">
          <el-input v-model="baiWangLogonForm.nsrsbh"></el-input>
        </el-form-item>
        <el-form-item label="地区编码" prop="dqbm">
          <el-select
            filterable
            v-model="baiWangLogonForm.dqbm"
            placeholder="请选择"
          >
            <el-option
              v-for="item in areaCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="接收短信的手机号码" prop="sjhm">
          <el-input v-model="baiWangLogonForm.sjhm" type="tel"></el-input>
        </el-form-item> -->
        <el-form-item label="登录账号" prop="dlzh">
          <el-input v-model="baiWangLogonForm.dlzh"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="dlmm">
          <el-input v-model="baiWangLogonForm.dlmm" type="password"></el-input>
        </el-form-item>
        <!-- <el-form-item label="短信验证码" prop="sms">
          <div class="smsArea">
            <el-input v-model="baiWangLogonForm.sms"></el-input>
            <el-button @click="gainSmsVerifyCode" :disabled="canSendSms">{{ btnText }}</el-button>
          </div>
        </el-form-item> -->
        <el-form-item label="登录身份" prop="dlsf">
          <el-select
            filterable
            v-model="baiWangLogonForm.dlsf"
            placeholder="请选择"
          >
            <el-option
              v-for="item in identityList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelBaiWangLogon">取 消</el-button>
        <el-button type="primary" @click="confirmBaiWangLogon">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 百旺全电人脸识别二维码 -->
    <el-dialog
      title="百旺全电人脸识别二维码"
      :visible.sync="isShowFaceQrCodeDialog"
      :before-close="
        () => {
          /*防止点到屏幕外面导致弹窗关闭*/
        }
      "
      :show-close="false"
      width="30%"
      v-el-drag-dialog
    >
      <div class="qrcode">
        <vue-qr v-if="qrcodeText != ''" :text="qrcodeText" :size="200"></vue-qr>
        <el-button
          type="primary"
          @click="getFaceCodeResult"
          :disabled="canQueryResult"
          >{{ queryBtnText }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2' // 引入qrcode
import {
  pkInfoList,
  findPkInfoSelect,
  findDivsionList,
  addpkInfo,
  pkInfoId,
  findByPkId,
  pkInvoiceTitle,
  getSysAdminCert,
  pkPayChangeConfig,
  getByPkIdAndType,
  getLogonVerifyCodeSmg,
  userLogon,
  getFaceVerifyQRCode,
  getFaceVerifyQRCodeResult,
} from '@/api/Parkinglot.js'
import AMap from 'AMap' // 引入高德地图
import oss from '@/network/ossUtils'
import { getCurrentSysAdmin } from '@/api/common.js'
import vueQr from 'vue-qr'

export default {
  components: {
    vueQr,
  },
  data() {
    var checkphone = (rule, value, callback) => {
      // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        // 引入methods中封装的检查手机格式的方法
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }
    // 税率效验规则
    var checktaxRate = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('格式示例：如税率为3%，填写0.03'))
      } else {
        var result = false
        var express = /(^[0-9]{1}$)|(^[0]{1}[\.]{1}[0-9]{2}$)/
        result = express.test(value)
        if (result) {
          callback()
        } else {
          callback(new Error('格式示例：如税率为3%，填写0.03'))
        }
        return result
      }
    }
    // 身份证效验
    var checkBankAccount = (rule, value, callback) => {
      if (value === undefined) {
        return callback(
          new Error('请输入销方银行账号(例如:xx银行xx分行营业部 1234567890)')
        )
      } else {
        var result = false
        var express = /[\u4e00-\u9fa5]+ [0-9]+/

        result = express.test(value)
        if (result) {
          callback()
        } else {
          callback(
            new Error('请输入销方银行账号(例如:xx银行xx分行营业部 1234567890)')
          )
        }
        return result
      }
    }
    // 联系人
    var checklinkMan = (rule, value, callback) => {
      if (value === undefined) {
        return callback(new Error('请输入正确联系人名称'))
      } else {
        var result = false
        var express = /^(?:[\u4e00-\u9fa5·]{2,16})$/

        result = express.test(value)
        if (result) {
          callback()
        } else {
          callback(new Error('请输入正确联系人名称'))
        }
        return result
      }
    }
    return {
      isShowData: false,
      isShowGLY: false,
      isShowAZZl: false,
      isShowAdd: false,
      isShowEdit: false,
      isShowSet: false,
      isShowxjzl: false,
      isShowCouponRecovery: false,
      isopencouponreturn: false,
      isShowBaiWangInvoiceDialog: false,
      isShowFaceQrCodeDialog: false,
      canSendSms: false, //是否可以发送短信
      btnText: '获取验证码',
      qrcodeText: '', //二维码内容
      bjshows: false,
      loading: false,
      tableData: [],
      query: {
        // 查询条件
        current: 1,
        size: 10,
        isOnline: undefined, // 是否在线0-否 1-是
        pkId: undefined, // 停车场id
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      appSecret: undefined,
      appKey: undefined,
      addForm: {
        id: undefined, // id
        pkName: undefined, // 停车场名称
        linkman: undefined, // 联系人
        isOnline: undefined, // 在线状态
        tel: undefined, // 停车场联系方式
        totalBerthNum: undefined, // 泊位总数
        provinceCode: undefined, // 省编码
        cityCode: undefined, // 市编码
        regionCode: undefined, // 区编码
        gmapX: undefined, // 高德经度
        gmapY: undefined, // 高德纬度
        address: undefined, // 详细地址
        subDomain: undefined, // 二级域名前缀
        feeScale: undefined, // 收费标准
        openTime: undefined, // 开放时间
        openBerthNum: undefined, // 开放泊位数
        tempTotalNum: undefined, // 临停车位数
        intrinsicTotalNum: undefined, // 月租车位数
        chargeTotalNum: undefined, // 充电桩车位数
        bmapX: undefined, // 百度经度
        bmapY: undefined, // 百度纬度
        visitorTotalNum: undefined, // 访客车位数
      },
      editForm: {
        id: undefined, // id
        pkName: undefined, // 停车场名称
        linkman: undefined, // 联系人
        isOnline: undefined, // 在线状态
        tel: undefined, // 停车场联系方式
        totalBerthNum: undefined, // 泊位总数
        provinceCode: undefined, // 省编码
        cityCode: undefined, // 市编码
        regionCode: undefined, // 区编码
        gmapX: undefined, // 高德经度
        gmapY: undefined, // 高德纬度
        address: undefined, // 详细地址
        subDomain: undefined, // 二级域名前缀
        feeScale: undefined, // 收费标准
        openTime: undefined, // 开放时间
        openBerthNum: undefined, // 开放泊位数
        tempTotalNum: undefined, // 临停车位数
        intrinsicTotalNum: undefined, // 月租车位数
        chargeTotalNum: undefined, // 充电桩车位数
        bmapX: undefined, // 百度经度
        bmapY: undefined, // 百度纬度
        visitorTotalNum: undefined, // 访客车位数
      },
      setForm: {
        id: '', // id
        pkId: '', // 停车场id
        invoiceProvider: 1, // 开票提供方 1诺诺网 2百旺 3..以后扩展
        providerConfigParam: undefined, //身份证号码
        appKey: undefined, //key
        appSecret: undefined, //Secret
        kpzdbs: undefined, //开票终端标识
        jqbh: undefined, //机器编号
        enterpriseCode: undefined, //企业代码
        dlzh: undefined, //企业账号
        saleOpenBank: undefined, //销方开户行
        saleName: '', // 销售方名称
        saleTaxnum: '', // 销售方税号
        saleAccount: '', // 销方银行账号
        salePhone: '', // 销方电话
        saleAddress: '', // 销方地址
        clerk: '', // 开票员
        payee: '', // 收款人
        checker: '', // 复核人
        taxrate: '', // 开票税率
        fwcqzshm: '', //房屋产权证书码
        bdcdz: '', //不动产地址
        bdcxxdz: '', //不动产详细地址
        zlqq: '', //租赁期起
        zlqz: '', //租赁期止
        kdsbz: '', //跨地市标志，如：Y
        mjdw: '', //面积单位，如：平方米
        goodsName: '', //开票货物名称
        goodsCode: '', //开票货物编码
        showMsg: '', //开票提示语
        remark: '', //备注
        token: '', //token
      },
      // 优惠券
      couponForm: {
        id: undefined, //id
        pkName: undefined, //停车场名称
        tel: undefined, //停车场联系方式
        totalBerthNum: undefined, //总车位数
        provinceCode: undefined, //省编码
        cityCode: undefined, //市编码
        regionCode: undefined, //区编码
        gmapX: undefined, //高德经度
        gmapY: undefined, //高德纬度
        address: undefined, //详细地址
        subDomain: undefined, //二级域名前缀
        isEnableVideo: undefined, //是否开启相机推流
        isOpenCouponReturn: 0, //该车场优惠券过期了是否退款给商家（定时任务） 1开启 0未开启（默认）
      },
      xjzlForm: {
        id: undefined,
        pkName: undefined,
        pkId: undefined,
        transferType: undefined,
        appPublicKeyFile: undefined,
        alipayPublicKeyFile: undefined,
        alipayCaFile: undefined,
        appPrivateKey: undefined,
        appId: undefined,
      },
      isShowxjzlForm: {
        transferType: undefined,
        pkId: undefined,
      },
      //百旺登录对象
      baiWangLogonForm: {},
      //地区代码集合
      areaCodeList: [
        {
          label: '安徽',
          value: 'anhui',
        },
        {
          label: '北京',
          value: 'beijing',
        },
        {
          label: '重庆',
          value: 'chongqing',
        },
        {
          label: '大连',
          value: 'dalian',
        },
        {
          label: '福建',
          value: 'fujian',
        },
        {
          label: '甘肃',
          value: 'gansu',
        },
        {
          label: '广东',
          value: 'guangdong',
        },
        {
          label: '广西',
          value: 'guangxi',
        },
        {
          label: '贵州',
          value: 'guizhou',
        },
        {
          label: '海南',
          value: 'hainan',
        },
        {
          label: '河北',
          value: 'hebei',
        },
        {
          label: '河南',
          value: 'henan',
        },
        {
          label: '黑龙江',
          value: 'heilongjiang',
        },
        {
          label: '湖北',
          value: 'hubei',
        },
        {
          label: '湖南',
          value: 'hunan',
        },
        {
          label: '吉林',
          value: 'jilin',
        },
        {
          label: '江苏',
          value: 'jiangsu',
        },
        {
          label: '江西',
          value: 'jiangxi',
        },
        {
          label: '辽宁',
          value: 'liaoning',
        },
        {
          label: '内蒙',
          value: 'neimeng',
        },
        {
          label: '宁波',
          value: 'ningbo',
        },
        {
          label: '宁夏',
          value: 'ningxia',
        },
        {
          label: '青岛',
          value: 'qingdao',
        },
        {
          label: '青海',
          value: 'qinghai',
        },
        {
          label: '厦门',
          value: 'xiamen',
        },
        {
          label: '山东',
          value: 'shandong',
        },
        {
          label: '山西',
          value: 'shanxi',
        },
        {
          label: '陕西',
          value: 'shaanxi',
        },
        {
          label: '上海',
          value: 'shanghai',
        },
        {
          label: '深圳',
          value: 'shenzhen',
        },
        {
          label: '四川',
          value: 'sichuan',
        },
        {
          label: '天津',
          value: 'tianjin',
        },
        {
          label: '西藏',
          value: 'xizang',
        },
        {
          label: '新疆',
          value: 'xinjiang',
        },
        {
          label: '云南',
          value: 'yunnan',
        },
        {
          label: '浙江',
          value: 'zhejiang',
        },
      ],
      //面积单位枚举
      areaUnitList: [
        {
          label: '平方米',
          value: '平方米',
        },
        {
          label: '亩',
          value: '亩',
        },
        {
          label: 'm²',
          value: 'm²',
        },
        {
          label: '平方千米',
          value: '平方千米',
        },
        {
          label: '公顷',
          value: '公顷',
        },
        {
          label: 'hm²',
          value: 'hm²',
        },
        {
          label: 'km²',
          value: 'km²',
        },
      ],
      //身份代码集合
      identityList: [
        {
          label: '财务负责人',
          value: '1',
        },
        {
          label: '法定代表人',
          value: '2',
        },
        {
          label: '办税人',
          value: '3',
        },
        {
          label: '购票员',
          value: '4',
        },
        {
          label: '普通管理员',
          value: '5',
        },
        {
          label: '开票员',
          value: '7',
        },
        {
          label: '社保经办人',
          value: '8',
        },
        {
          label: '销售人员',
          value: '10',
        },
      ],
      installCmd: null, // 安装指令
      formRules: {
        // 添加表单的验证规则
        invoiceProvider: [
          { required: true, message: '请输入开票提供方', trigger: 'blur' },
        ],
        appSecret: [
          {
            required: true,
            message: '请输入Secret',
            trigger: ['blur', 'change'],
          },
        ],
        appKey: [
          {
            required: true,
            message: '请输入Key',
            trigger: ['blur', 'change'],
          },
        ],
        providerConfigParam: [
          {
            required: true,
            message: '请输入身份认证',
            trigger: ['blur', 'change'],
          },
        ],
        dlzh: [
          {
            required: true,
            message: '请输入企业账号',
            trigger: ['blur', 'change'],
          },
        ],
        linkman: [
          {
            required: true,
            validator: checklinkMan,
            trigger: ['blur', 'change'],
          },
        ],
        // 开票终端标识
        kpzdbs: [
          {
            required: true,
            message: '请输入开票终端标识',
            trigger: ['blur', 'change'],
          },
        ],
        jqbh: [
          {
            required: true,
            message: '请输入机器编号',
            trigger: ['blur', 'change'],
          },
        ],
        enterpriseCode: [
          {
            required: true,
            message: '请输入企业代码',
            trigger: ['blur', 'change'],
          },
        ],
        saleName: [
          { required: true, message: '请输入销售方名称', trigger: 'blur' },
        ],
        saleTaxnum: [
          { required: true, message: '请输入销售方税号', trigger: 'blur' },
        ],
        saleAccount: [
          {
            required: true,
            validator: checkBankAccount,
            trigger: ['blur', 'change'],
          },
        ],
        saleOpenBank: [
          {
            required: true,
            message: '请输入销方开户行',
            trigger: ['blur', 'change'],
          },
        ],
        salePhone: [
          { required: true, message: '请输入销方电话', trigger: 'blur' },
        ],
        saleAddress: [
          { required: true, message: '请输入销方地址', trigger: 'blur' },
        ],
        clerk: [{ required: true, message: '请输入开票员', trigger: 'blur' }],
        payee: [{ required: true, message: '请输入收款人', trigger: 'blur' }],
        checker: [{ required: true, message: '请输入复核人', trigger: 'blur' }],
        pkName: [
          { required: true, message: '请输入停车场名称', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        totalBerthNum: [
          { required: true, message: '请输入泊位总数', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        appId: [
          { required: true, message: '请输入APPID', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        appPrivateKey: [
          {
            required: true,
            message: '请输入开发者应用私钥',
            trigger: 'blur',
          },
        ],
        taxrate: [
          {
            required: true,
            validator: checktaxRate,
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: true,
            validator: checkphone,
            trigger: ['blur', 'change'],
          },
        ],
        fwcqzshm: [
          { required: true, message: '产权证号码不能为空', trigger: 'blur' },
        ],
        bdcdz: [
          { required: true, message: '不动产地址不能为空', trigger: 'blur' },
        ],
        bdcxxdz: [
          {
            required: true,
            message: '不动产详细地址不能为空',
            trigger: 'blur',
          },
        ],
        zlqq: [
          { required: true, message: '租赁期起时间不能为空', trigger: 'blur' },
        ],
        zlqz: [
          { required: true, message: '租赁期止时间不能为空', trigger: 'blur' },
        ],
        kdsbz: [
          { required: true, message: '跨地市标志不能为空', trigger: 'blur' },
        ],
        mjdw: [
          { required: true, message: '面积单位不能为空', trigger: 'blur' },
        ],
      },
      levelObj: {
        level: undefined, // 1查询省 2查询市 3查询区
        provinceCode: undefined, // 查询市必填
        cityCode: undefined, // 查询区必填
      },
      options: [],
      onlineStatuoptions: [
        {
          label: '在线',
          value: 1,
        },
        {
          label: '不在线',
          value: 0,
        },
      ],
      options2: [],
      options3: [],
      lnglat: '', // 逆解析经纬度
      adcode: '', // 6位编码
      activeName: 'first',
      fileList1: [],
      fileList2: [],
      fileList3: [],
      uploadzs1: '上传证书',
      uploadzs2: '上传证书',
      uploadzs3: '上传证书',
      h5imgList: [],
      wximgList: [],
      alipaybimgList: [],
      //表单验证区域
      invoiceRules: {
        nsrsbh: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
        dlzh: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
        dlmm: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
        dqbm: [{ required: true, message: '内容不能为空', trigger: 'change' }],
        // sjhm:[
        //   { required: true, message: '内容不能为空', trigger: 'blur' }
        // ],
        // sms:[
        //   { required: true, message: '内容不能为空', trigger: 'blur' }
        // ],
        dlsf: [{ required: true, message: '内容不能为空', trigger: 'change' }],
      },
      queryBtnText: '扫码后请点击获取结果',
      canQueryResult: false,
    }
  },
  created() {
    this.getCurrentSysAdmin()
  },
  methods: {
    hqh5QrcodeUrl(scope) {
      let query = {
        pkid: scope.row.id,
      }
      // pk-admin-jy.pingzhi.com
      var domain = window.location.hostname
      var newPath = ''
      const patt = /jy/
      if (patt.test(domain)) {
        newPath =
          'https://pk-h5-jy.pingzhi.com' +
          `/#/pages/inputplateNos/index/?pk_id=${query.pkid}`
      } else {
        newPath =
          process.env.VUE_APP_WEB_API +
          `/#/pages/inputplateNos/index/?pk_id=${query.pkid}`
      }
      const qrCode = new QRCode(document.createElement('div'), {
        text: newPath,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
      let imageUrl = qrCode._el.firstChild.toDataURL()
      return imageUrl
    },
    isCellPhone(val) {
      let mobileReg = /(^0\d{2,3}\-\d{7,8}$)|(^1[3|4|5|6|7|8][0-9]{9}$)/
      if (!mobileReg.test(val)) {
        return false
      } else {
        return true
      }
    },
    onchange1(file, fileList) {
      this.xjzlForm.appPublicKeyFile = file
    },
    onchange2(file, fileList) {
      this.xjzlForm.alipayPublicKeyFile = file
    },
    onchange3(file, fileList) {
      this.xjzlForm.alipayCaFile = file
    },
    uploadHttp1() {},
    uploadHttp2() {},
    uploadHttp3() {},
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`)
    },
    beforeRemove1(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeRemove2(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeRemove3(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    getCurrentSysAdmin() {
      // 查询登入用户信息
      getCurrentSysAdmin().then((res) => {
        if (res.data.code === '200') {
          if (res.data.data.roleIdList[0] == 1) {
            this.isShowGLY = true
          }
          this.findPkInfoSelect()
        }
      })
    },
    map() {
      // 地图
      this.$nextTick(() => {
        var map = new AMap.Map('container', {
          zoom: 11, //级别
          center: center, //中心点坐标
          viewMode: '3D', //使用3D视图
          resizeEnable: true,
        })
        map.plugin(['AMap.ToolBar'], function () {
          map.addControl(new AMap.ToolBar())
        })
        if (location.href.indexOf('&guide=1') !== -1) {
          map.setStatus({ scrollWheel: false })
        }
        //输入提示
        var autoOptions = {
          input: 'tipinput',
        }
        var auto = new AMap.Autocomplete(autoOptions)
        var placeSearch = new AMap.PlaceSearch({
          map: map,
        }) //构造地点查询类
        AMap.event.addListener(auto, 'select', select) //注册监听，当选中某条记录时会触发
        function select(e) {
          placeSearch.setCity(e.poi.adcode)
          placeSearch.search(e.poi.name) //关键字查询查询
        }
        var center = map.getCenter()
        //为地图注册click事件获取鼠标点击出的经纬度坐标
        map.on('click', (e) => {
          this.addForm.gmapX = e.lnglat.getLng()
          this.addForm.gmapY = e.lnglat.getLat()
          this.editForm.gmapX = e.lnglat.getLng()
          this.editForm.gmapY = e.lnglat.getLat()
          this.lnglat = e.lnglat.getLng() + ',' + e.lnglat.getLat()
          this.locationToAddress()
        })
      })
    },
    locationToAddress() {
      // 经纬度转详细地址
      this.$axios({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/regeo?parameters',
        params: {
          key: '3b7a88fa011cc25b116c2cd355292b1d',
          location: this.lnglat,
        },
      }).then((res) => {
        this.adcode = res.data.regeocode.addressComponent.adcode
        this.addForm.provinceCode = res.data.regeocode.addressComponent.province
        this.addForm.cityCode = res.data.regeocode.addressComponent.city
        this.addForm.regionCode = res.data.regeocode.addressComponent.district
        this.addForm.address = res.data.regeocode.formatted_address
        this.editForm.provinceCode =
          res.data.regeocode.addressComponent.province
        this.editForm.cityCode = res.data.regeocode.addressComponent.city
        this.editForm.regionCode = res.data.regeocode.addressComponent.district
        this.editForm.address = res.data.regeocode.formatted_address
      })
    },
    map1() {
      // 地图
      this.$nextTick(() => {
        var map = new AMap.Map('containers', {
          zoom: 11, //级别
          center: center, //中心点坐标
          viewMode: '3D', //使用3D视图
          resizeEnable: true,
        })
        map.plugin(['AMap.ToolBar'], function () {
          map.addControl(new AMap.ToolBar())
        })
        if (location.href.indexOf('&guide=1') !== -1) {
          map.setStatus({ scrollWheel: false })
        }
        //输入提示
        var autoOptions = {
          input: 'tipinput',
        }
        var auto = new AMap.Autocomplete(autoOptions)
        var placeSearch = new AMap.PlaceSearch({
          map: map,
        }) //构造地点查询类
        AMap.event.addListener(auto, 'select', select) //注册监听，当选中某条记录时会触发
        function select(e) {
          placeSearch.setCity(e.poi.adcode)
          placeSearch.search(e.poi.name) //关键字查询查询
        }
        var center = map.getCenter()
        //为地图注册click事件获取鼠标点击出的经纬度坐标
        map.on('click', (e) => {
          this.addForm.gmapX = e.lnglat.getLng()
          this.addForm.gmapY = e.lnglat.getLat()
          this.editForm.gmapX = e.lnglat.getLng()
          this.editForm.gmapY = e.lnglat.getLat()
          this.lnglat = e.lnglat.getLng() + ',' + e.lnglat.getLat()
          this.locationToAddress()
        })
      })
    },
    sheng() {
      // 省
      this.loading = true
      this.levelObj.level = 1
      this.levelObj.provinceCode = undefined
      this.levelObj.cityCode = undefined
      findDivsionList(this.levelObj).then((res) => {
        this.options = res.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        this.loading = false
        this.addForm.cityCode = undefined
        this.addForm.regionCode = undefined
        this.editForm.cityCode = undefined
        this.editForm.regionCode = undefined
      })
    },
    shi() {
      this.loading = true
      this.levelObj.level = 2
      if (this.bjshows) {
        if (!this.editForm.provinceCode) {
          return this.$message.warning('请选择省')
        } else {
          this.levelObj.provinceCode = this.editForm.provinceCode
        }
      } else {
        if (!this.addForm.provinceCode) {
          return this.$message.warning('请选择省')
        } else {
          this.levelObj.provinceCode = this.addForm.provinceCode
        }
      }
      findDivsionList(this.levelObj).then((res) => {
        this.options2 = res.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        this.addForm.regionCode = undefined
        this.editForm.regionCode = undefined
        this.loading = false
      })
    },
    qu() {
      this.loading = true
      this.levelObj.level = 3
      if (this.bjshows) {
        if (!this.editForm.cityCode) {
          return this.$message.warning('请选择市')
        } else {
          this.levelObj.cityCode = this.editForm.cityCode
        }
      } else {
        if (!this.addForm.cityCode) {
          return this.$message.warning('请选择市')
        } else {
          this.levelObj.cityCode = this.addForm.cityCode
        }
      }
      findDivsionList(this.levelObj).then((res) => {
        this.options3 = res.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        this.loading = true
      })
    },
    pkInfoList() {
      // 列表
      pkInfoList(this.query).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
        this.h5imgList = res.data.data.list.map((item) => item.h5PrepayQrcode)
        this.wximgList = res.data.data.list.map((item) => item.wxMiniProQrcode)
        this.alipaybimgList = res.data.data.list.map(
          (item) => item.alipayMiniProQrcode
        )

        if (this.tableData) {
          this.isShowData = true
          this.loading = false
        }
      })
    },
    pkInfoListcx() {
      // 查询
      this.query.current = 1
      this.pkInfoList()
    },
    pkInfoListcz() {
      this.query.isOnline = undefined
      this.pkInfoList()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.pkInfoList()
        }
      })
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.pkInfoList()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.pkInfoList()
    },
    isShowAddDialog() {
      // 添加显示弹框
      this.isShowAdd = true
      this.map()
      this.addForm.provinceCode = undefined
      this.addForm.cityCode = undefined
      this.addForm.regionCode = undefined
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 添加
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        this.isShowAdd = false
        this.loading = true
        if (this.adcode) {
          this.addForm.provinceCode = this.adcode.slice(0, -2)
          this.addForm.cityCode = this.adcode.slice(0, -4)
          this.addForm.regionCode = this.adcode
          addpkInfo(this.addForm).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('添加成功')
              this.pkInfoList()
              this.adcode = ''
              this.loading = false
            } else {
              this.loading = false
            }
          })
        } else {
          addpkInfo(this.addForm).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('添加成功')
              this.pkInfoList()
              this.loading = false
            } else {
              this.loading = false
            }
          })
        }
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      this.bjshows = true
      pkInfoId(scope.row.id).then((res) => {
        this.editForm = res.data.data
        this.options = [
          {
            value: this.editForm.provinceCode,
            label: this.editForm.provinceName,
          },
        ]
        this.options2 = [
          {
            value: this.editForm.cityCode,
            label: this.editForm.cityName,
          },
        ]
        this.options3 = [
          {
            value: this.editForm.regionCode,
            label: this.editForm.regionName,
          },
        ]
        this.isShowEdit = true
        this.map1()
      })
    },
    editClick() {
      // 编辑
      this.isShowEdit = false
      this.loading = true
      if (this.adcode) {
        this.editForm.provinceCode = this.adcode.slice(0, -2)
        this.editForm.cityCode = this.adcode.slice(0, -4)
        this.editForm.regionCode = this.adcode
        addpkInfo(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.$message.success('编辑成功')
            this.pkInfoList()
            this.adcode = ''
            this.loading = false
          } else {
            this.loading = false
          }
        })
      } else {
        addpkInfo(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.$message.success('编辑成功')
            this.pkInfoList()
            this.loading = false
          } else {
            this.loading = false
          }
        })
      }
    },
    setClose() {
      // 发票设置 清空input数据
      this.$refs.setRef.resetFields()
    },
    isShowSetDialog(scope) {
      // 发票设置
      const pkId = { pkId: scope.row.id }
      findByPkId(pkId).then((res) => {
        if (res.data.code === '200') {
          if (res.data.data) {
            let datalist = JSON.parse(JSON.stringify(res.data.data))
            if (res.data.data.invoiceProvider == 4) {
              let providerConfigParam = JSON.parse(
                res.data.data.providerConfigParam
              )
              this.setForm = {
                id: datalist.id, // id
                pkId: datalist.pkId, // 停车场id
                invoiceProvider: datalist.invoiceProvider, // 开票提供方 1诺诺网 2百旺 3..以后扩展
                providerConfigParam: undefined,
                saleOpenBank: datalist.saleOpenBank, //销方开户行
                kpzdbs: undefined, //开票终端标识
                jqbh: undefined, //机器编号
                appSecret: providerConfigParam.appSecret, //Secret
                appKey: providerConfigParam.appKey, //Key
                enterpriseCode: undefined, //企业代码
                dlzh: undefined, //企业账号
                saleName: datalist.saleName, // 销售方名称
                saleTaxnum: datalist.saleTaxnum, // 销售方税号
                saleAccount: datalist.saleAccount, // 销方银行账号
                salePhone: datalist.salePhone, // 销方电话
                saleAddress: datalist.saleAddress, // 销方地址
                clerk: datalist.clerk, // 开票员
                payee: datalist.payee, // 收款人
                checker: datalist.checker, // 复核人
                taxrate: datalist.taxrate, // 开票税率
                fwcqzshm: datalist.fwcqzshm, //房屋产权证书码
                bdcdz: datalist.bdcdz, //不动产地址
                bdcxxdz: datalist.bdcxxdz, //不动产详细地址
                zlqq: datalist.zlqq, //租赁期起
                zlqz: datalist.zlqz, //租赁期止
                kdsbz: datalist.kdsbz, //跨地市标志，如：Y
                mjdw: datalist.mjdw, //面积单位，如：平方米
                goodsName: datalist.goodsName, //开票货物名称
                goodsCode: datalist.goodsCode, //开票货物编码
                showMsg: datalist.showMsg, //开票提示语
                remark: providerConfigParam.remark, //备注
                token: providerConfigParam.token, //token
              }
            }
            if (res.data.data.invoiceProvider == 3) {
              let list =
                datalist.providerConfigParam != null
                  ? JSON.parse(datalist.providerConfigParam)
                  : undefined
              this.setForm = {
                id: datalist.id, // id
                pkId: datalist.pkId, // 停车场id
                invoiceProvider: datalist.invoiceProvider, // 开票提供方 1诺诺网 2百旺 3..以后扩展
                enterpriseCode:
                  list != undefined ? list.enterpriseCode : undefined, //企业代码
                dlzh: list != undefined ? list.dlzh : undefined, //企业账号
                saleOpenBank: datalist.saleOpenBank, //销方开户行
                kpzdbs: undefined, //开票终端标识
                jqbh: undefined, //机器编号
                appSecret: undefined, //Secret
                appKey: undefined, //Key
                saleName: datalist.saleName, // 销售方名称
                saleTaxnum: datalist.saleTaxnum, // 销售方税号
                saleAccount: datalist.saleAccount, // 销方银行账号
                salePhone: datalist.salePhone, // 销方电话
                saleAddress: datalist.saleAddress, // 销方地址
                clerk: datalist.clerk, // 开票员
                payee: datalist.payee, // 收款人
                checker: datalist.checker, // 复核人
                taxrate: datalist.taxrate, // 开票税率
                showMsg: datalist.showMsg, //开票提示语
                fwcqzshm: datalist.fwcqzshm, //房屋产权证书码
                bdcdz: datalist.bdcdz, //不动产地址
                bdcxxdz: datalist.bdcxxdz, //不动产详细地址
                zlqq: datalist.zlqq, //租赁期起
                zlqz: datalist.zlqz, //租赁期止
                kdsbz: datalist.kdsbz, //跨地市标志，如：Y
                mjdw: datalist.mjdw, //面积单位，如：平方米
                goodsName: datalist.goodsName, //开票货物名称
                goodsCode: datalist.goodsCode, //开票货物编码
              }
            }
            if (res.data.data.invoiceProvider == 1) {
              this.setForm = {
                id: datalist.id, // id
                pkId: datalist.pkId, // 停车场id
                invoiceProvider: datalist.invoiceProvider, // 开票提供方 1诺诺网 2百旺 3..以后扩展
                providerConfigParam: datalist.providerConfigParam,
                kpzdbs: undefined, //开票终端标识
                jqbh: undefined, //机器编号
                appSecret: undefined, //Secret
                appKey: undefined, //Key
                enterpriseCode: undefined, //企业代码
                dlzh: undefined, //企业账号
                saleName: datalist.saleName, // 销售方名称
                saleTaxnum: datalist.saleTaxnum, // 销售方税号
                saleAccount: datalist.saleAccount, // 销方银行账号
                saleOpenBank: datalist.saleOpenBank, //销方开户行
                salePhone: datalist.salePhone, // 销方电话
                saleAddress: datalist.saleAddress, // 销方地址
                clerk: datalist.clerk, // 开票员
                payee: datalist.payee, // 收款人
                checker: datalist.checker, // 复核人
                taxrate: datalist.taxrate, // 开票税率
                goodsName: datalist.goodsName, //开票货物名称
                goodsCode: datalist.goodsCode, //开票货物编码
              }
            }
            if (res.data.data.invoiceProvider == 2) {
              let providerConfigParam = JSON.parse(
                res.data.data.providerConfigParam
              )
              this.setForm = {
                id: datalist.id, // id
                pkId: datalist.pkId, // 停车场id
                invoiceProvider: datalist.invoiceProvider, // 开票提供方 1诺诺网 2百旺 3..以后扩展
                providerConfigParam: undefined,
                kpzdbs: providerConfigParam.kpzdbs, //开票终端标识
                jqbh: providerConfigParam.jqbh, //机器编号
                appSecret: undefined, //Secret
                appKey: undefined, //Key
                enterpriseCode: providerConfigParam.enterpriseCode, //企业代码
                dlzh: undefined, //企业账号
                saleName: datalist.saleName, // 销售方名称
                saleTaxnum: datalist.saleTaxnum, // 销售方税号
                saleAccount: datalist.saleAccount, // 销方银行账号
                saleOpenBank: datalist.saleOpenBank, //销方开户行
                salePhone: datalist.salePhone, // 销方电话
                saleAddress: datalist.saleAddress, // 销方地址
                clerk: datalist.clerk, // 开票员
                payee: datalist.payee, // 收款人
                checker: datalist.checker, // 复核人
                taxrate: datalist.taxrate, // 开票税率
                goodsName: datalist.goodsName, //开票货物名称
                goodsCode: datalist.goodsCode, //开票货物编码
              }
            }
          } else {
            this.setForm = {
              id: '', // id
              pkId: scope.row.id, // 停车场id
              invoiceProvider: 1, // 开票提供方 1诺诺网 2百旺 3..以后扩展
              providerConfigParam: undefined,
              saleOpenBank: undefined, //销方开户行
              kpzdbs: undefined, //开票终端标识
              jqbh: undefined, //机器编号
              appSecret: undefined, //Secret
              appKey: undefined, //Key
              enterpriseCode: undefined, //企业代码
              dlzh: undefined, //企业账号
              saleName: '', // 销售方名称
              saleTaxnum: '', // 销售方税号
              saleAccount: '', // 销方银行账号
              salePhone: '', // 销方电话
              saleAddress: '', // 销方地址
              clerk: '', // 开票员
              payee: '', // 收款人
              checker: '', // 复核人
              taxrate: '', // 开票税率
              goodsName: '', //开票货物名称
              goodsCode: '', //开票货物编码
              showMsg: '', //开票提示语
              remark: '', //备注
              token: '', //token
            }
          }
          this.isShowSet = true
        }
      })
    },
    // 百旺全电开票登录
    baiWangInvoiceDialog(item) {
      this.isShowBaiWangInvoiceDialog = true
      this.baiWangLogonForm.isVerificationCode = 0
      this.baiWangLogonForm.pkId = item.id
      // this.baiWangLogonForm.dlsf = "2" // 法定代表人
      // this.baiWangLogonForm.refreash = 1
    },
    confirmBaiWangLogon() {
      this.$refs.invoiceForm.validate((valid) => {
        if (valid) {
          this.isShowBaiWangInvoiceDialog = false
          userLogon(this.baiWangLogonForm, {
            pk_id: this.baiWangLogonForm.pkId,
          }).then((res) => {
            let result = JSON.parse(res.data.data)
            if (result.message && result.message.code == '0') {
              this.$message.success('登录成功')
              let query = {
                nsrsbh: this.baiWangLogonForm.nsrsbh,
                loginName: this.baiWangLogonForm.dlzh,
                ewmlx: 1,
              }
              //登录成功后发起人脸认证
              getFaceVerifyQRCode(query, {
                pk_id: this.baiWangLogonForm.pkId,
              }).then((res) => {
                console.log(res)
                result = JSON.parse(res.data.data)
                console.log(result)
                if (result.message.code && result.message.code == '0') {
                  this.baiWangLogonForm.rzid = result.message.content.rzid
                  this.qrcodeText = result.message.content.ewminfo
                  this.isShowFaceQrCodeDialog = true
                }
              })
            } else {
              if (result.resCode) {
                this.$message.error(
                  '错误代码：' + result.resCode + ' 错误原因：' + result.resMsg
                )
              } else if (result.message.code) {
                this.$message.error(
                  '错误代码：' +
                    result.message.code +
                    ' 错误原因：' +
                    result.message.messages
                )
              }
            }
          })
        }
      })
    },
    //获取扫码验证结果
    getFaceCodeResult() {
      let query = {
        nsrsbh: this.baiWangLogonForm.nsrsbh,
        loginName: this.baiWangLogonForm.dlzh,
        ewmlx: 1,
        rzid: this.baiWangLogonForm.rzid,
      }
      this.canQueryResult = true
      this.queryBtnText = '正在查询'
      getFaceVerifyQRCodeResult(query, {
        pk_id: this.baiWangLogonForm.pkId,
      }).then((res) => {
        console.log(res)
        let result = JSON.parse(res.data.data)
        console.log(result)
        if (
          result.message &&
          result.message.code == '0' &&
          result.message.content.rzjg == '2'
        ) {
          this.$message.success('认证成功')
          this.isShowFaceQrCodeDialog = false
        } else {
          if (result.resCode) {
            this.$message.error(
              '认证失败，错误代码：' +
                result.resCode +
                ' 错误原因：' +
                result.resMsg
            )
          } else if (result.message.code) {
            this.$message.error(
              '认证失败，错误代码：' +
                result.message.code +
                ' 错误原因：' +
                result.message.messages
            )
          }
          this.isShowFaceQrCodeDialog = false
        }
        this.canQueryResult = false
        this.queryBtnText = '扫码后请点击获取结果'
      })
    },
    cancelBaiWangLogon() {
      this.isShowBaiWangInvoiceDialog = false
      this.baiWangLogonForm = {}
    },
    // 获取短信验证码
    // gainSmsVerifyCode(){
    //   if(!this.baiWangLogonForm.nsrsbh){
    //     this.$message.warning("请输入纳税人识别号")
    //     return
    //   }
    //   if(!this.baiWangLogonForm.dqbm){
    //     this.$message.warning("请输入地区编码")
    //     return
    //   }
    //   if(!this.baiWangLogonForm.sjhm){
    //     this.$message.warning("请输入手机号")
    //     return
    //   }
    //   if(!this.baiWangLogonForm.dlzh){
    //     this.$message.warning("请输入登录账号")
    //     return
    //   }
    //   if(!this.baiWangLogonForm.dlmm){
    //     this.$message.warning("请输入登录密码")
    //     return
    //   }
    //   getLogonVerifyCodeSmg(this.baiWangLogonForm).then(res=>{
    //     if(res.data.data && res.data.data.code == '0'){
    //       this.$message.success("验证码已发送")
    //       this.canSendSms = true
    //       let count = 60
    //       let interval = setInterval(()=>{
    //         this.btnText = --count
    //       },1000)
    //       let that = this
    //       setTimeout(()=>{
    //         clearInterval(interval);
    //         this.btnText = "获取验证码"
    //         that.canSendSms = false
    //       }, 60000)
    //     }
    //   })
    // },
    setClick() {
      // 发票设置
      this.$refs.setRef.validate((valid) => {
        if (!valid) return
        if (this.setForm.invoiceProvider == 1) {
          this.setForm.providerConfigParam = this.setForm.providerConfigParam
        } else if (this.setForm.invoiceProvider == 2) {
          this.setForm.providerConfigParam = {
            kpzdbs: this.setForm.kpzdbs,
            jqbh: this.setForm.jqbh,
            enterpriseCode: this.setForm.enterpriseCode,
          }
          this.setForm.kpzdbs = undefined
          this.setForm.jqbh = undefined
          this.setForm.enterpriseCode = undefined
        } else if (this.setForm.invoiceProvider == 3) {
          this.setForm.providerConfigParam = {
            enterpriseCode: this.setForm.enterpriseCode,
            dlzh: this.setForm.dlzh,
          }
        } else if (this.setForm.invoiceProvider == 4) {
          this.setForm.providerConfigParam = {
            appKey: this.setForm.appKey,
            appSecret: this.setForm.appSecret,
            token: this.setForm.token,
            remark: this.setForm.remark,
            favouredPolicyFlag: this.setForm.favouredPolicyFlag,
          }
          this.setForm.appKey = undefined
          this.setForm.appSecret = undefined
        }
        pkInvoiceTitle(this.setForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowSet = false
            this.$message.success('保存成功')
          }
        })
      })
    },
    isShowmodeDialog(scope) {
      // 发票设置
      this.$router.push({
        path: 'Paymentmethod',
        query: { id: scope.row.id },
      })
    },
    isShowGtDialog(scope) {
      // 岗亭端
      getSysAdminCert({ pkId: scope.row.id }).then((res) => {
        if (res.data.code === '200') {
          const sign = res.data.data.sign
          pkInfoId(scope.row.id).then((res) => {
            if (res.data.code === '200') {
              if (res.data.data.subDomain) {
                window.open(
                  `http://pkbox-${scope.row.id}-` +
                    res.data.data.subDomain +
                    '.pingzhi.com:9000/Login?sign=' +
                    sign
                )
              } else {
                this.$message.success('请配置盒子远程访问网址')
              }
            }
          })
        }
      })
    },
    isShowCmdDialog(row) {
      // 安装指令
      if (!row.subDomain) {
        this.$message.error('请先配置停车场英文名称（远程访问地址）')
        return
      }
      /*
      oss.ossGetUrl('shell/pk-box.sh').then((url) => {
        this.installCmd = `curl -fsSL '${url}' -o pk-box.sh && sudo bash pk-box.sh install --id ${row.id} --name ${row.subDomain} 2>&1 | tee -a pk-box.log`
        this.isShowAZZl = true
      })
*/
      // 脚本下载地址
      let url = 'http://192.168.18.146:10000/pk-box.sh'
      this.installCmd = `curl -fsSL '${url}' -o pk-box.sh && sudo bash pk-box.sh install --id ${row.id} --name ${row.subDomain} 2>&1 | tee -a pk-box.log`
      this.isShowAZZl = true
    },
    handleClick(tab, event) {
      if (tab.index === 0) {
        this.isShowxjzlForm.transferType = 1
        this.xjzlForm.transferType = 1
      } else {
        this.isShowxjzlForm.transferType = 2
        this.xjzlForm.transferType = 2
      }
    },
    isShowxjzlDialog(scope) {
      // 现金找零
      this.isShowxjzlForm.pkId = scope.row.id
      this.isShowxjzlForm.transferType = 1
      this.xjzlForm.transferType = 1
      this.xjzlForm.pkName = scope.row.pkName
      this.xjzlForm.pkId = scope.row.id
      getByPkIdAndType(this.isShowxjzlForm).then((res) => {
        if (res.data.code === '200') {
          if (res.data.data) {
            this.xjzlForm = res.data.data
            if (res.data.data.appPublicKeyPath) {
              this.fileList1 = [
                {
                  name: res.data.data.appPublicKeyPath,
                  url: res.data.data.appPublicKeyPath,
                },
              ]
              this.uploadzs1 = '修改证书'
            }
            if (res.data.data.alipayPublicKeyPath) {
              this.fileList2 = [
                {
                  name: res.data.data.alipayPublicKeyPath,
                  url: res.data.data.alipayPublicKeyPath,
                },
              ]
              this.uploadzs2 = '修改证书'
            }
            if (res.data.data.alipayCaPath) {
              this.fileList3 = [
                {
                  name: res.data.data.alipayCaPath,
                  url: res.data.data.alipayCaPath,
                },
              ]
              this.uploadzs3 = '修改证书'
            }
          }
          this.isShowxjzl = true
        }
      })
    },
    xjzlClick() {
      // 现金找零
      let formData = new FormData()
      if (this.xjzlForm.id) {
        formData.append('id', this.xjzlForm.id)
      }
      formData.append('pkId', this.xjzlForm.pkId)
      formData.append('transferType', this.xjzlForm.transferType)
      if (this.xjzlForm.appPublicKeyFile) {
        formData.append('appPublicKeyFile', this.xjzlForm.appPublicKeyFile.raw)
      }
      if (this.xjzlForm.alipayPublicKeyFile) {
        formData.append(
          'alipayPublicKeyFile',
          this.xjzlForm.alipayPublicKeyFile.raw
        )
      }
      if (this.xjzlForm.alipayCaFile) {
        formData.append('alipayCaFile', this.xjzlForm.alipayCaFile.raw)
      }
      formData.append('appPrivateKey', this.xjzlForm.appPrivateKey)
      formData.append('appId', this.xjzlForm.appId)
      pkPayChangeConfig(formData).then((res) => {
        if (res.data.code === '200') {
          this.$message.success('保存成功')
          this.isShowxjzl = false
        }
      })
    },
    onCopySuccess() {
      this.$message.success('复制成功')
    },
    onCopyError() {
      this.$message.error('复制失败')
    },
    // 关闭优惠劵回收
    couponRecoveryClose() {
      this.isShowCouponRecovery = false
    },
    // 打开优惠券回收
    couponRecoveryClick(scope) {
      this.isShowCouponRecovery = true
      this.couponForm.id = scope.id
      this.couponForm.pkName = scope.pkName
      this.couponForm.tel = scope.id
      this.couponForm.totalBerthNum = scope.totalBerthNum
      this.couponForm.provinceCode = scope.provinceCode
      this.couponForm.cityCode = scope.cityCode
      this.couponForm.regionCode = scope.regionCode
      this.couponForm.gmapX = scope.gmapX
      this.couponForm.gmapY = scope.gmapY
      this.couponForm.address = scope.address
      this.couponForm.subDomain = scope.subDomain
      this.couponForm.isEnableVideo = scope.isEnableVideo
      this.couponForm.isOpenCouponReturn = scope.isOpenCouponReturn
      this.isopencouponreturn = Boolean(scope.isOpenCouponReturn)
    },
    // 优惠券回收开关
    couponRecoveryChange(item) {
      this.$confirm(
        `确定执行${item ? '有效' : '失效'}操作, 是否继续？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.isopencouponreturn = this.isopencouponreturn
          this.couponForm.isOpenCouponReturn = Number(this.isopencouponreturn)
          addpkInfo(this.couponForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success('保存成功')
                this.findPkInfoSelect()
                this.isShowCouponRecovery = false
              }
            })
            .catch(() => {
              this.$message.success('保存失败')
            })
        })
        .catch(() => {
          this.$message.error('取消操作')
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#container,
#containers {
  position: static;
  width: 100%;
  height: 300px;
  border: 1px solid #333;
}
.formitems {
  position: relative;
  #myPageTop {
    position: absolute;
    top: 50px;
    z-index: 9999;
    width: 180px;
    height: 30px;
    padding: 0;
    margin: 0;
    border: 0;
    #tipinput {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 30px;
      padding: 0;
      margin: 0;
    }
  }
}
.smsArea {
  display: flex;
  justify-content: space-between;
}
.qrcode {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
::v-deep .el-dialog__body {
  padding: 7px 20px;
}

::v-deep .el-image-viewer__close {
  color: #333;
}
::v-deep .el-image-viewer__mask {
  position: fixed;
  opacity: 0;
  background-color: #333;
}
::v-deep .el-image-viewer__canvas {
  height: 80%;
  padding-top: 100px;
  box-sizing: border-box;
}
::v-deep .el-image-viewer__wrapper {
  top: 50px;
  left: 20%;
  right: 20%;
  bottom: 50px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 20px 1px #333;
}
</style>
